import { Login } from '@phpcreation/frontend-components-react';
import React from 'react';

type Props = {
  appEnv: string | undefined;
};

const IndexLogin = (props: Props) => {
  return (
    <Login
      sampleResource="tenants"
      fqcn_bui={{ Bundle: 'securityBundle', Unit: 'login', Interface: 'login' }}
      devUsername={process.env.NEXT_PUBLIC_USERNAME}
      devPassword={process.env.NEXT_PUBLIC_PASSWORD}
      appEnv={props.appEnv}
      redirectPage='feedback'
    />
  );
};

export function getStaticProps() {
  const appEnv = process.env.APP_ENV;

  return {
    props: {
      appEnv
    }
  }
}

export default IndexLogin;
